import React from "react";
import loadable from "@loadable/component"
import { StaticImage } from "gatsby-plugin-image";
import {
  Section,
  SectionWrapper,
  SassyText,
} from "../../components/core/commonExports";
import { useMixpanel } from "../../context/MixpanelContext";
import { getQueryParams } from "../../utils/queryParamUtils";
import { MetaComponent } from "../../components/MetaComponent";
import GLPNavbar from "../../components/campaigns/GLPNavbar";
import { getPageName } from "../../utils/getCurrentPageName";
import { OnDesktop } from "../../components/DesktopMobileComps";
import HeroSection from "../../components/campaigns/HeroSection";
import { HeroBg, QuoteModalPlaceholder, HeroSectionData, HeroSectionContainer, HeroImgContainer, ModalContainer, HeroSectionHeading, HeroSectionCTA } from "../../components/campaigns/SharedComponents";
import { LeadFormProvider } from "../../context/LeadFormContext";
import { useCampaignLPStates } from "../../hooks/useCampaignLPStates";

const LeadForm = loadable(() => import("../../components/LeadForm"));
const Footer = loadable(() => import("../../components/campaigns/Footer"), {
  resolveComponent: (components) => components.Footer,
});
const SelfServe = loadable(() => import("../../components/SelfServe"))

const ExtraBenefits = loadable(() => import(/* webpackPrefetch: true */ "../../components/campaigns/ExtraBenefits"))
const StepsToProtectBusiness = loadable(() => import(/* webpackPrefetch: true */ "../../components/StepsToProtectBusiness/StepsToProtectBusinessGLPs"))
const ProtectionFromThreats = loadable(() => import(/* webpackPrefetch: true */ "../../components/campaigns/ProtectionFromThreats"))
const QuotesFromCompanies = loadable(() => import(/* webpackPrefetch: true */ "../../components/campaigns/glp6/QuotesFromCompanies"))
const ReviewSection = loadable(() => import(/* webpackPrefetch: true */ "../../components/campaigns/google1/ReviewSection"))
const FooterSection = loadable(() => import(/* webpackPrefetch: true */ "../../components/campaigns/google1/FooterSection"))

const IndexPage = () => {
  const mixpanel = useMixpanel();

  const {
    leadformWrapperRef,
    leadformRef,
    scrollToLeadForm,
    hideQuoteCTAs,
    setHideQuoteCTAs,
    hideMinPremium,
    setHideMinPremium,
    screen, 
    setScreen,
    hideHeroSection,
    isSelfServe,
    fetchEstimatedPremium,
    selfServeRef,
    handleCallScheduleClick
  } = useCampaignLPStates()

  // capture and store the query params beforehand
  getQueryParams();

  const pageName = getPageName()

  const handleGetQuoteClick = (e, ctaNumber ="") => {
    mixpanel.track(`Clicked 'Get a Quote'- ${ctaNumber}(${pageName})`);
    scrollToLeadForm()
  };

  const hidePremium = () => {
    setHideMinPremium(true)
  }

  const heroSectionDefaultComp = (
    <>
      <HeroSectionHeading>
        Insure your business property today!<br/>
        {!hideMinPremium && <SassyText>Starting at just ₹8 per day</SassyText>}
      </HeroSectionHeading>
      <HeroImgContainer>
        <StaticImage 
          src="../../assets/images/auto-parts-shop.webp"
          alt="insure business starting at just ₹8 per day"
          width={348}
          height={196}
          sizes="(min-width: 348px) 348px"
          loading="eager"
          placeholder="none"
        />
      </HeroImgContainer>
      {!hideQuoteCTAs && <OnDesktop><HeroSectionCTA label="GET A QUOTE" onClick={(e, ctaNumber=1) => handleGetQuoteClick(e, ctaNumber)} /></OnDesktop>}
    </>
  )

  return (
    <>
      <MetaComponent loadYellowAiScript={false} loadFbPixelScript={false} />
      <GLPNavbar 
        hideQuoteCTA={hideQuoteCTAs || isSelfServe} 
        showCallHelplineCTA={isSelfServe} 
        hidePremium={hidePremium} 
        handleGetQuoteClick={scrollToLeadForm}
        handleCallScheduleClick={handleCallScheduleClick}
      />
      <LeadFormProvider>
        {isSelfServe ? (
          <SelfServe ref={selfServeRef} fetchEstimatedPremium={fetchEstimatedPremium} fallback={<div style={{height: "100vh", width: "100%"}} />} />
        ) : null}
        <Section background="#ffffff"  style={{display: isSelfServe ? "none" : "block", marginTop: '4rem', position: "relative"}}>
          <HeroBg />
          <SectionWrapper mobileStyles={{padding: "24px 0", paddingTop: hideHeroSection ? "0" : "6px"}} desktopStyles={{paddingTop: "40px"}}>
            <HeroSectionData>
              <HeroSectionContainer screen={screen} hideHeroSection={hideHeroSection}>
                <HeroSection 
                  screen={screen} 
                  setScreen={setScreen}
                  heroSectionDefaultComp={heroSectionDefaultComp}
                />
              </HeroSectionContainer>
              <ModalContainer hideHeroSection={hideHeroSection} ref={leadformWrapperRef}>
                <LeadForm 
                  ref={leadformRef}
                  formType="google" 
                  setHideQuoteCTAs={setHideQuoteCTAs} 
                  renderAsPopup={false} 
                  style={{width: 'auto'}} 
                  hideMinPremium={() => setHideMinPremium(true)} 
                  fallback={<QuoteModalPlaceholder />} 
                  setHeroSectionScreen={setScreen} 
                />
              </ModalContainer>
            </HeroSectionData>
          </SectionWrapper>
        </Section>
      </LeadFormProvider>
      {!isSelfServe ? <StepsToProtectBusiness /> : null}
      <ExtraBenefits />
      <ProtectionFromThreats />
      <QuotesFromCompanies sectionBg="#fff" title={<SassyText>Get Quotes from over 10+ Reputed Insurers</SassyText>} mobileStyles={{}} desktopStyles={{}} />
      <ReviewSection lpName={pageName} handleGetQuoteClick={(e, ctaNumber=2) => handleGetQuoteClick(e, ctaNumber)} />
      <FooterSection sectionBg="linear-gradient(180deg, #FFFFFF 0%, #FFEBDF 100%)" handleGetQuoteClick={(e, ctaNumber=3) => handleGetQuoteClick(e, ctaNumber)} glp2v1={true} />
      <Footer style={{background: "#fff"}} />
    </>
  );
};

export default IndexPage;
